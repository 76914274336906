import React from "react";
import Layout from "../components/Layout";

export default function Error404() {
  return (
    <Layout>
      <div>404!</div>
    </Layout>
  );
}
